<template>
  <div class="block">

    <div class="block-title">
      <h1>{{ counter + 1 }}. Jautājums ar atbildēm</h1>
      <div class="button-block">
        <button class="toggle-question" @click.prevent="toggleQuestion()">
          <i class="fas fa-caret-down" v-if="!toggle"></i>
          <i class="fas fa-caret-up" v-if="toggle"></i>
        </button>
        <button class="duplicate-question" @click.prevent="$emit('duplicateQuestion')">
          <i class="fas fa-clone"></i>
        </button>
        <button class="remove-question" @click.prevent="$emit('deleteQuestion')">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>

    <div class="form-container" :class="{ hidden: !toggle }" v-if="first_time_opened">
      <div class="input-block">
        <label>Jautājums:</label>
        <HTMLEditor ref="HTMLEditorContent" v-on:load="addAnswerHTMLEditorChangeListener"/>
      </div>

      <div class="input-block">
        <label for="question-type">Jautājuma tips:</label>
        <select id="question-type" v-model="question.type" @change="changeQuestionType">
          <option value="0"></option>
          <option value="1" v-if="[1, 2, 4].includes(parseInt(quiz.general_information.type))">Viena iespējamā atbilde</option>
          <option value="2" v-if="[1, 2, 4].includes(parseInt(quiz.general_information.type))">Vairākas iespējamās atbildes</option>
          <option value="3" v-if="[3].includes(parseInt(quiz.general_information.type))">Ne/Pareizās atbildes</option>
        </select>
      </div>

      <template v-if="[3].includes(parseInt(question.type)) && [3].includes(parseInt(quiz.general_information.type))">
        <div class="input-block">
          <label>Pareizās atbildes lapa:</label>
          <HTMLEditor ref="correctAnswerHTMLEditorContent" v-on:load="addHCorrectAnswerHTMLEditorChangeListener"/>
        </div>

        <div class="input-block">
          <label>Nepareizās atbildes lapa:</label>
          <HTMLEditor ref="wrongAnswerHTMLEditorContent" v-on:load="addWrongAnswerHTMLEditorChangeListener"/>
        </div>
      </template>

      <div v-if="[1, 2, 3].includes(parseInt(question.type))">
        <hr>
        <HTMLEditorAnswer
            v-for="(answer, index) in question.answers" v-bind:key="answer"
            v-bind:old_answer="answer"
            v-bind:quiz="quiz"
            v-on:updateAnswerContent="updateAnswerContent($event, index)"
            v-on:addAnswer="addAnswer"
            v-on:deleteAnswer="deleteAnswer(index)"
            v-on:duplicateAnswer="duplicateAnswer(index)"
        >
        </HTMLEditorAnswer>
      </div>
    </div>

  </div>
</template>

<script>
import tippy from 'tippy.js'
import HTMLEditor from '../../html-editor/HTMLEditor'
import HTMLEditorAnswer from './answers/HTMLEditorAnswer'
import {useRoute} from "vue-router";

export default {
  name: 'QuestionAndAnswers',
  components: {HTMLEditorAnswer, HTMLEditor},
  emits: ['deleteQuestion', 'duplicateQuestion', 'updateQuestionContent', 'updateQuestionType', 'updateQuestionAnswers'],
  props: ['old_question', 'quiz', 'counter'],
  data() {
    return {
      toggle: true,
      first_time_opened: false,
      question: {
        question_id: null,
        content_html: null,
        content_json: null,
        type: null,
        correct_answer_content_json: null,
        correct_answer_content_html: null,
        wrong_answer_content_json: null,
        wrong_answer_content_html: null,
        answers: [{
          answer_id: null,
          correct_answer: null,
          content_html: null,
          content_json: null,
          result_page_numbers: null,
          next_question_number: null
        }]
      }
    }
  },
  watch: {
    old_question: {
      immediate: true,
      handler: function (data) {
        this.question = data
      }
    }
  },
  mounted() {
    const route = useRoute()
    this.quiz_id = route.params.id
    if (this.quiz_id) {
      this.toggle = false
    } else {
      this.first_time_opened = true
    }
    tippy('.remove-question', {
      content: 'Dzēst jautājumu',
    });
    tippy('.toggle-question', {
      content: 'Slēpt/Rādīt jautājumu',
    });
    tippy('.duplicate-question', {
      content: 'Dublicēt jautājumu',
    });
  },
  methods: {
    changeQuestionType() {
      this.question.answers = [{
        correct_answer: null,
        content_html: null,
        content_json: null,
        correct_answer_content_json: null,
        correct_answer_content_html: null,
        wrong_answer_content_json: null,
        wrong_answer_content_html: null
      }]
      this.$emit('updateQuestionAnswers', this.question.answers)
      this.$emit('updateQuestionType', this.question.type)
    },
    addAnswer() {
      this.question.answers.push({
        correct_answer: null,
        content_html: null,
        content_json: null,
        correct_answer_content_json: null,
        correct_answer_content_html: null,
        wrong_answer_content_json: null,
        wrong_answer_content_html: null
      })
    },
    deleteAnswer(index) {
      this.question.answers.splice(index, 1)
      this.$emit('updateQuestionAnswers', this.question.answers)
    },
    updateAnswerContent(answer, index) {
      this.question.answers[index].content_html = answer.content_html
      this.question.answers[index].content_json = answer.content_json
      this.$emit('updateQuestionAnswers', this.question.answers)
    },
    addAnswerHTMLEditorChangeListener() {
      const self = this
      if (this.question.content_json instanceof Object) {
        this.$refs.HTMLEditorContent.editor.loadDesign(JSON.parse(JSON.stringify(this.question.content_json)))
      } else {
        this.$refs.HTMLEditorContent.editor.loadDesign(JSON.parse(this.question.content_json))
      }
      this.$refs.HTMLEditorContent.editor.addEventListener('design:updated', function () {
        self.$refs.HTMLEditorContent.editor.exportHtml((data) => {
              self.question.content_html = data.html
              self.question.content_json = data.design
              self.$emit('updateQuestionContent', self.question)
            }
        )
      })
    },
    addHCorrectAnswerHTMLEditorChangeListener() {
      const self = this
      this.$refs.correctAnswerHTMLEditorContent.editor.loadDesign(JSON.parse(this.question.correct_answer_content_json))
      this.$refs.correctAnswerHTMLEditorContent.editor.addEventListener('design:updated', function () {
        self.$refs.correctAnswerHTMLEditorContent.editor.exportHtml((data) => {
              self.question.correct_answer_content_html = data.html
              self.question.correct_answer_content_json = data.design
              self.$emit('updateQuestionContent', self.question)
            }
        )
      })
    },
    addWrongAnswerHTMLEditorChangeListener() {
      const self = this
      this.$refs.wrongAnswerHTMLEditorContent.editor.loadDesign(JSON.parse(this.question.wrong_answer_content_json))
      this.$refs.wrongAnswerHTMLEditorContent.editor.addEventListener('design:updated', function () {
        self.$refs.wrongAnswerHTMLEditorContent.editor.exportHtml((data) => {
              self.question.wrong_answer_content_html = data.html
              self.question.wrong_answer_content_json = data.design
              self.$emit('updateQuestionContent', self.question)
            }
        )
      })
    },
    toggleQuestion() {
      this.first_time_opened = true
      this.toggle = !this.toggle
    },
    duplicateAnswer(index) {
      let duplicate = this.question.answers[index]
      this.question.answers.push({
        answer_id: null,
        correct_answer: duplicate.correct_answer,
        content_html: duplicate.content_html,
        content_json: duplicate.content_json,
        result_page_numbers: duplicate.result_page_numbers,
        next_question_number: duplicate.next_question_number
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include block;

@include inputBlock;
</style>
