<template>
  <div class="background">
    <div class="main-wrapper">
      <div class="logo-wrapper">
        <a href="/">
          <img src="@/assets/images/company-logo.png">
        </a>
      </div>
      <div class="form-wrapper">
        <form @submit.prevent="submit" autocomplete="off" spellcheck="false">
          <div class="form-group">
            <label>E-pasts:</label>
            <input type="email" v-model="email" class="login-input-field"/>
          </div>
          <div class="form-group">
            <label>Parole:</label>
            <input type="password" v-model="password" class="login-input-field"/>
          </div>
          <div class="form-group">
            <input type="submit" class="login-field-button" value="Ienākt"/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Login',
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (this.$store.token !== null) {
        this.$router.push('dashboard')
      }
    })
  },
  methods: {
    submit() {
      if (this.password.length > 0) {
        this.axios.post(process.env.VUE_APP_API_LINK + '/login', {
          email: this.email,
          password: this.password
        })
            .then(response => {
              if (response.data.status === true) {
                this.$store.commit('updateToken', response.data.token)
                this.$store.commit('updateProfile', response.data.user)
                this.$router.push('/dashboard')
              } else {
                this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastDanger)
              }
            })
      } else {
        this.$moshaToast("Parole nav ievadīta", this.$store.state.toast.moshaToastDanger)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

.background {
  font-family: $text-font;
  font-size: 14px;
  background: url("~@/assets/images/riga.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  min-height: 100vh;

  .main-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 150px;

    .logo-wrapper {
      padding-bottom: 50px;

      img {
        width: 300px;
        height: auto;

        @media only screen and (max-width: $mini-breakpoint) {
          width: 200px;
        }
      }
    }

    .form-wrapper {
      width: 400px;

      form {
        padding: 20px 15px 15px 20px;
        background: #eee;
      }

      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        label {
          font-size: 16px;
          font-weight: 200;
          margin-bottom: 5px;
          text-transform: uppercase;
        }

        .login-input-field {
          height: 40px;
          padding: 5px 10px;
          font-size: 16px;
        }

        .login-field-button {
          border: none;
          background-color: #e70e43;
          color: #fff;
          height: 40px;
          line-height: 30px;
          text-transform: uppercase;
          transition: 0.3s;

          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }

          &:disabled {
            opacity: 0.4;
          }
        }

        &:last-child {
          margin-bottom: 20px;
        }
      }

      @media only screen and (max-width: $mini-breakpoint) {
        width: 100%;
      }
    }

    @media only screen and (max-width: $mini-breakpoint) {
      width: 100%;
      padding: 100px 15px 0 15px;
    }
  }
}
</style>
