<template>

  <div class="input-block" v-if="parseInt(quiz.general_information.type) === 3">
    <label>Pareizā atbilde:</label>
    <div class="checkbox-buttons">
      <div class="checkbox-button">
        <input type="checkbox" value="1" v-model="answer.correct_answer" true-value="1" :checked="answer.correct_answer">
      </div>
    </div>
  </div>

  <div class="input-block">
    <label>Atbilde:</label>
    <HTMLEditor ref="HTMLEditorContent" v-on:load="addHTMLEditorChangeListener"/>
    <button class="add-answer" @click.prevent="$emit('addAnswer')">
      <i class="fa fa-plus"></i>
    </button>
    <button class="duplicate-answer" @click.prevent="$emit('duplicateAnswer')">
      <i class="fas fa-clone"></i>
    </button>
    <button class="remove-answer" @click.prevent="$emit('deleteAnswer')">
      <i class="far fa-trash-alt"></i>
    </button>
  </div>

  <div class="input-block" v-if="parseInt(quiz.general_information.type) === 1">
    <label>Rezultātu lapas numuri:</label>
    <input type="text" v-model="answer.result_page_numbers">
  </div>

  <div class="input-block" v-if="[4].includes(parseInt(quiz.general_information.type))">
    <label>Rezultātu lapas punkti:</label>
    <input type="text" v-model="answer.result_page_points">
  </div>

  <div class="input-block" v-if="[1, 4].includes(parseInt(quiz.general_information.type))">
    <label>Nākamā jautājuma numurs:</label>
    <input type="text" v-model="answer.next_question_number">
  </div>

  <hr>
</template>

<script>
import tippy from "tippy.js";
import HTMLEditor from "../../../html-editor/HTMLEditor";

export default {
  name: 'HTMLEditorAnswer',
  components: {HTMLEditor},
  emits: ['updateAnswerContent', 'addAnswer', 'deleteAnswer', 'duplicateAnswer'],
  props: ['old_answer', 'quiz'],
  data() {
    return {
      answer: {
        answer_id: null,
        correct_answer: null,
        content_html: null,
        content_json: null,
        result_page_numbers: null,
        result_page_points: null,
        next_question_number: null
      }
    }
  },
  watch: {
    old_answer: {
      immediate: true,
      handler: function (data) {
        this.answer = data
      }
    }
  },
  mounted() {
    tippy('.remove-answer', {
      content: 'Dzēst atbildi',
    });
    tippy('.add-answer', {
      content: 'Pievienot atbildi',
    });
    tippy('.duplicate-answer', {
      content: 'Dublicēt atbildi',
    });
  },
  methods: {
    addHTMLEditorChangeListener() {
      const self = this
      if (this.answer.content_json instanceof Object) {
        this.$refs.HTMLEditorContent.editor.loadDesign(JSON.parse(JSON.stringify(this.answer.content_json)))
      } else {
        this.$refs.HTMLEditorContent.editor.loadDesign(JSON.parse(this.answer.content_json))
      }
      this.$refs.HTMLEditorContent.editor.addEventListener('design:updated', function () {
        self.$refs.HTMLEditorContent.editor.exportHtml((data) => {
              self.answer.content_html = data.html
              self.answer.content_json = data.design
              self.$emit('updateAnswerContent', self.answer)
            }
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include inputBlock;
</style>
