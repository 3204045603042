<template>
  <PageLoader v-if="showPageLoader"/>
  <div class="page-container" v-if="token && $route.name !== 'PublicQuiz'">
    <MainMenu></MainMenu>
    <div class="page-content">
      <Header></Header>
      <router-view/>
    </div>
  </div>
  <div v-else>
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/templates/Header'
import MainMenu from '@/components/templates/MainMenu'
import {mapState} from 'vuex';
import axios from "axios";
import PageLoader from '@/components/page-loader/PageLoader'


export default {
  name: 'App',
  data() {
    return {
      public_quiz: false,
      showPageLoader: false
    }
  },
  components: {PageLoader, MainMenu, Header},
  computed: {
    ...mapState(['token'])
  },
  mounted() {
    const self = this
    axios.interceptors.request.use(function (config) {
      self.showPageLoader = true
      return config
    }, function (error) {
      self.showPageLoader = false
      return Promise.reject(error)
    });
    axios.interceptors.response.use(function (response) {
      self.showPageLoader = false
      return response;
    }, function (error) {
      self.showPageLoader = false
      return Promise.reject(error)
    });
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_includes.scss";

.page-container {
  display: flex;
  margin: 0;
  padding: 0;
  font-family: $text-font;
  font-size: 14px;
  background: linear-gradient(135deg, #e0d1cc 0, #e3d5d0 30%, #fff 62%, #bcd1d5 100%) no-repeat center center fixed;

  .page-content {
    width: 100%;
  }

  @media only screen and (max-width: $medium-breakpoint) {
    flex-direction: column;
  }
}
</style>
