<template>
  <div class="total-container">
    <div class="total-content">
      <div class="total-image">
        <i class="far fa-newspaper"></i>
      </div>
      <div class="total-text-container">
        <p><span>{{ total_quiz_count }}</span></p>
        <p>Aptaujas</p>
      </div>
    </div>
    <div class="total-content">
      <div class="total-image">
        <i class="far fa-question-circle"></i>
      </div>
      <div class="total-text-container">
        <p><span>{{ total_question_count }}</span></p>
        <p>Jautājumi</p>
      </div>
    </div>
    <div class="total-content">
      <div class="total-image">
        <i class="fas fa-exclamation"></i>
      </div>
      <div class="total-text-container">
        <p><span>{{ total_answer_count }}</span></p>
        <p>Atbildes</p>
      </div>
    </div>
    <div class="total-content">
      <div class="total-image">
        <i class="far fa-chart-bar"></i>
      </div>
      <div class="total-text-container">
        <p><span>{{ total_submitted_quizzes_count }}</span></p>
        <p>Aizpildīts</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      total_quiz_count: null,
      total_question_count: null,
      total_answer_count: null,
      total_submitted_quizzes_count: null
    }
  },
  mounted() {
    this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/statistics', {}, {
      headers: {
        Authorization: 'Bearer ' + this.$store.state.token
      }
    })
        .then(response => {
          if (response.data.status === true) {
            this.total_quiz_count = response.data.results.total_quiz_count
            this.total_question_count = response.data.results.total_question_count
            this.total_answer_count = response.data.results.total_answer_count
            this.total_submitted_quizzes_count = response.data.results.total_submitted_quizzes_count
          }
        })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include block;

.total-container {
  display: flex;
  flex-wrap: wrap;

  .total-image {
    width: 50%;
    text-align: center;

    i {
      font-size: 60px;
      color: #e70e44;
    }
  }

  .total-content {
    min-width: 180px;
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    display: flex;
    align-items: center;
    margin: 15px;

    .total-text-container {
      margin-left: 10px;
      text-align: center;

      p {
        margin: 0;

        span {
          font-weight: bold;
          font-size: 30px;
        }

        &:nth-child(2) {
          color: #b3b3b3;
        }
      }
    }

    @media only screen and (max-width: $small-breakpoint) {
      min-width: calc(50% - 30px);
      width: calc(50% - 30px);
    }

    @media only screen and (max-width: $mini-breakpoint) {
      width: 100%;
    }
  }
}
</style>
