<template>
  <div class="block">
    <div class="button-block">
      <button @click="goToQuizList">
        <i class="fas fa-arrow-left"></i>
        <span class="button-name">Atpakaļ</span>
      </button>
    </div>
    <div class="quiz-info">
      <div class="info-block">
        <div class="title">Nosaukums:</div>
        <div class="value">{{ quiz_name }}</div>
      </div>
      <div class="info-block">
        <div class="title">Izpildītas reizes:</div>
        <div class="value">{{ respondents_count }}</div>
      </div>
    </div>
    <div class="tabs-container">
      <div class="tabs-header">
        <div class="tab-button" :class="active_tab === 1 ? 'active' : ''">
          <a href="#" @click="active_tab = 1">Respondenti</a>
        </div>
        <div class="tab-button" :class="active_tab === 2 ? 'active' : ''">
          <a href="#" @click="active_tab = 2">Statistika</a>
        </div>
      </div>
      <div class="tabs">
        <div class="tab" v-if="active_tab === 1">
          <div class="respondents">
            <div v-for="(respondent, index) in respondents" :key="respondent.id" class="respondent">
              <div class="row" @click="toggleRespondents(respondents.length - index)" :class="toggle === respondents.length - index ? 'active' : ''">
                <div class="main-info">
                  <b>#{{ respondents.length - index }} </b> {{ respondent.created_at_formatted }}
                </div>
              </div>
              <div class="quiz-result-info" v-if="toggle === respondents.length - index">
                <div class="respondent-info">
                  <div v-for="question in respondent.questions" :key="question.id" class="respondent-info-row">
                    <div class="left"><b>{{ question.sort_number }}.</b> Jautājuma atbildes:</div>
                    <div class="right">
                      <div class="answer" v-for="answer in question.answers" :key="answer.id">
                        <b>{{ answer.sort_number }}.</b> atbilde
                        <template v-if="answer.correctly_answered === 1"><b>pareiza</b></template>
                      </div>
                    </div>
                  </div>
                  <div class="respondent-info-row" v-if="quiz_type !== 2">
                    <div class="left"><b>{{ respondent.result_page_number }}.</b> Rezultātu lapa</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab" v-if="active_tab === 2">
          <div class="statistics">
            <div v-for="(question, index) in questions" :key="question.id" class="statistic">
              <div class="row" @click="toggleQuestions(questions.length - index)" :class="toggle_question === questions.length - index ? 'active' : ''">
                <b>{{ index + 1 }}. Jautājums</b>
              </div>
              <div class="quiz-result-info" v-if="toggle_question === questions.length - index">
                <div class="statistic-info">
                  <div class="statistic-info-row">
                    <div class="left">
                      <div v-for="(answer, index) in question.answers" :key="answer.id" class="answer">
                        <b>{{ index + 1 }}. atbilde</b> - {{ answer.total_answered_percent }}% ({{ answer.total_answered_count }})
                        <template v-if="answer.correct === 1"><b>pareiza</b></template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";

export default {
  name: 'Results',
  data() {
    return {
      active_tab: 1,
      toggle: null,
      toggle_question: null,
      quiz_name: null,
      quiz_type: null,
      respondents_count: null,
      respondents: [],
      questions: []
    }
  },
  mounted() {
    const route = useRoute()
    this.quiz_id = route.params.id
    this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/results', {
      quiz_id: this.quiz_id
    }, {
      headers: {
        Authorization: 'Bearer ' + this.$store.state.token
      }
    })
        .then(response => {
          if (response.data.status === true) {
            this.respondents_count = response.data.results.respondents_count
            this.quiz_name = response.data.results.quiz_name
            this.quiz_type = response.data.results.quiz_type
            this.respondents = response.data.results.respondents
            this.questions = response.data.results.questions
          }
        })
  },
  methods: {
    goToQuizList() {
      this.$router.push('/quizzes/list')
    },
    toggleRespondents(respondent_number) {
      if (this.toggle === respondent_number) {
        this.toggle = null
      } else {
        this.toggle = respondent_number
      }
    },
    toggleQuestions(question_number) {
      if (this.toggle_question === question_number) {
        this.toggle_question = null
      } else {
        this.toggle_question = question_number
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include block;

@include buttonBlock(flex-start);

@include inputBlock;

.button-block {
  margin-bottom: 15px;
}

.tabs-container {
  width: 100%;

  .tabs-header {
    display: flex;
    border: 1px solid $red-accent;

    .tab-button {
      width: 100%;

      a {
        line-height: 40px;
        transition: 0.3s;
        font-size: 14px;
        color: $red-accent;
        font-weight: bold;
        text-decoration: none;
        display: block;
        padding: 0 15px;
        text-transform: uppercase;

        &:hover {
          background: $red-hover;
          color: $red-accent;
        }
      }

      &.active {
        a {
          background-color: $red-accent;
          color: #fff;
        }
      }
    }
  }

  .tabs {
    .tab {
      .respondents {
        font-size: 13px;

        .respondent {
          .row {
            cursor: pointer;
            display: flex;
            padding: 7px 15px;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            transition: 0.3s;

            &.active {
              background: $red-accent;
              color: #fff;
            }

            &:hover {
              background: $red-accent;
              color: #fff;
            }
          }

          .quiz-result-info {
            border: 1px solid $red-accent;

            .respondent-info {
              .respondent-info-row {
                display: flex;
                border-bottom: 1px solid $red-hover;
                padding: 7px 15px;

                .left {
                  text-align: right;
                  margin-right: 5px;
                }

                .right {
                  margin-left: 5px;
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      .statistics {
        .statistic {
          .row {
            cursor: pointer;
            display: flex;
            padding: 7px 15px;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            transition: 0.3s;

            &.active {
              background: $red-accent;
              color: #fff;
            }

            &:hover {
              background: $red-accent;
              color: #fff;
            }
          }

          .quiz-result-info {
            border: 1px solid $red-accent;

            .statistic-info {
              .statistic-info-row {
                display: flex;
                border-bottom: 1px solid $red-hover;
                padding: 7px 15px;

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.quiz-info {
  .info-block {
    display: flex;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;

    .title {
      width: 230px;
    }

    .value {
      width: calc(100% - 230px);
    }
  }
}
</style>
