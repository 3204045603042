<template>
  <div class="block">
    <div class="block-title">
      <h1>{{ counter + 1 }}. Rezultātu lapa</h1>
      <div class="button-block">
        <button class="toggle-result-page" @click.prevent="toggleResultPage()">
          <i class="fas fa-caret-down" v-if="!toggle"></i>
          <i class="fas fa-caret-up" v-if="toggle"></i>
        </button>
        <button class="duplicate-result-page" @click.prevent="$emit('duplicateResultPage')">
          <i class="fas fa-clone"></i>
        </button>
        <button class="remove-result-page" @click.prevent="$emit('deleteResultPage')">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
    <div class="form-container" :class="{ hidden: !toggle }" v-if="first_time_opened">

      <div class="input-block">
        <label>Saturs:</label>
        <HTMLEditor ref="HTMLEditorContent" v-on:load="addHTMLEditorChangeListener"/>
      </div>

      <div class="input-block" v-if="[4].includes(parseInt(quiz.general_information.type))">
        <label>Punkti no:</label>
        <input type="text" v-model="result_page.points_min" @change="$emit('updateResultPageContent', result_page)">
      </div>

      <div class="input-block" v-if="[4].includes(parseInt(quiz.general_information.type))">
        <label>Punkti līdz:</label>
        <input type="text" v-model="result_page.points_max" @change="$emit('updateResultPageContent', result_page)">
      </div>

      <div class="input-block">
        <label>Saites parametrs:</label>
        <input type="text" v-model="result_page.link_parameter" @change="$emit('updateResultPageContent', result_page)">
      </div>

      <div class="input-block">
        <label>Share quote:</label>
        <input type="text" v-model="result_page.share_quote" @change="$emit('updateResultPageContent', result_page)">
      </div>

    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import HTMLEditor from '../../html-editor/HTMLEditor'
import {useRoute} from "vue-router";

export default {
  name: 'ResultPage',
  components: {HTMLEditor},
  emits: ['deleteResultPage', 'updateResultPageContent', 'duplicateResultPage'],
  props: ['old_result_page', 'counter', 'quiz'],
  data() {
    return {
      toggle: true,
      first_time_opened: false,
      result_page: {
        result_page_id: null,
        content_html: null,
        content_json: null,
        points_min: null,
        points_max: null,
        link_parameter: null,
        share_quote: null
      }
    }
  },
  watch: {
    old_result_page: {
      immediate: true,
      handler: function (data) {
        this.result_page = data
        this.result_page.link_parameter = data.link_parameter
        this.result_page.share_quote = data.share_quote
      }
    }
  },
  mounted() {
    const route = useRoute()
    this.quiz_id = route.params.id
    if (this.quiz_id) {
      this.toggle = false
    } else {
      this.first_time_opened = true
    }
    tippy('.toggle-result-page', {
      content: 'Slēpt/Rādīt rezultātu lapu',
    });
    tippy('.remove-result-page', {
      content: 'Dzēst rezultātu lapu',
    });
    tippy('.duplicate-result-page', {
      content: 'Dublicēt rezultātu lapu',
    });
  },
  methods: {
    addHTMLEditorChangeListener() {
      const self = this
      if (this.result_page.content_json instanceof Object) {
        this.$refs.HTMLEditorContent.editor.loadDesign(JSON.parse(JSON.stringify(this.result_page.content_json)))
      } else {
        this.$refs.HTMLEditorContent.editor.loadDesign(JSON.parse(this.result_page.content_json))
      }
      this.$refs.HTMLEditorContent.editor.addEventListener('design:updated', function () {
        self.$refs.HTMLEditorContent.editor.exportHtml((data) => {
            self.result_page.content_html = data.html
            self.result_page.content_json = data.design
            self.$emit('updateResultPageContent', self.result_page)
          }
        )
      })
    },
    toggleResultPage() {
      this.first_time_opened = true
      this.toggle = !this.toggle
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include block;

@include inputBlock;
</style>
