<template>
  <div class="block">
    <div class="button-block">
      <button @click="goToAddNewUserForm">
        <i class="fa fa-plus"></i>
        <span class="button-name">Pievienot administratoru</span>
      </button>
    </div>
    <table>
      <thead>
      <tr>
        <th>ID</th>
        <th>Nosaukums</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(user, index) in users" :key="index" :user-key="user.id">
        <td>{{ user.id }}</td>
        <td>{{ user.name }} {{ user.surname }} / {{ user.email }}</td>
        <td>
          <div class="buttons">
            <a href="#" class="button js-tooltip-edit" @click="goToUserEditForm(user.id)">
              <i class="far fa-edit"></i>
            </a>
            <a href="#" class="button js-tooltip-delete" @click="userDelete(index, user.id)">
              <i class="far fa-trash-alt"></i>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapState} from "vuex";
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css'

export default {
  name: 'Users',
  computed: {
    ...mapState(['users', 'user'])
  },
  methods: {
    goToUserEditForm(user_id) {
      this.$router.push('/users/edit/' + user_id)
    },
    goToAddNewUserForm() {
      this.$router.push('/users/new')
    },
    userDelete(index, user_id) {
      if (confirm("Vai tiešām vēlaties idzēst administratoru?")) {
        this.axios.post(process.env.VUE_APP_API_LINK + '/users/delete', {
          user_id: user_id
        }, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.token
          }
        })
            .then(response => {
              if (response.data.status === true) {
                this.users.splice(index, 1)
                if (user_id === this.user.id) {
                  this.$store.commit('logoutUser')
                  this.$router.push('/')
                }
                this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastSuccess)
              } else {
                this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastDanger)
              }
            })
      }
    },
  },
  mounted() {
    this.$store.dispatch('fetchAllUsers')
    tippy('.js-tooltip-edit', {
      content: 'Labot',
    });
    tippy('.js-tooltip-delete', {
      content: 'Dzēst',
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

body {
  font-family: $text-font;
}

@include block;

@include buttonBlock(flex-start);

.button-block {
  margin-bottom: 15px;
}

table {
  font-size: 16px;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid $red-accent;
}

table th {
  transition: 0.3s;
  text-align: left;
  background: $red-accent;
  color: #FFF;
  padding: 8px;
  min-width: 30px;
}

table td {
  text-align: left;
  padding: 8px;

  @media only screen and (max-width: $mini-breakpoint) {
    font-size: 13px;
  }
}

table td:last-child, table td:first-child {
  border-right: none;
  width: 1px;
  white-space: nowrap;
}

table tbody tr:nth-child(2n) td {
  background: $red-hover;
}

.buttons {
  display: flex;
}

button, a {
  padding: 5px;
  height: 30px;
  min-width: 30px;
  text-decoration: none;
  font-size: 12px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $red-accent;
  cursor: pointer;
  background-color: $red-accent;
  background-repeat: no-repeat;
  transition: 0.3s;
  margin: 0 5px;

  i {
    color: #fff;
    transition: 0.3s;
  }

  &:hover {
    background-color: $red-hover;

    i {
      color: $red-accent;
    }
  }
}
</style>

