<template>
  <div class="quiz-wrapper">
    <div class="quiz">
      <div v-if="!finished_quiz || quiz.general_information.type === 2" class="question">
        <template v-if="quiz.general_information.intro_status === 1 && !finished_quiz">
          <div v-html="quiz.general_information.intro_html"></div>
          <div v-html="quiz.general_information.next_button_html" @click="quiz.general_information.intro_status = 0"></div>
        </template>
        <template v-else>
          <template v-for="(question, index) in quiz.questions" v-bind:key="question">
            <template v-if="current_question === index">

              <template v-if="[1, 3].includes(parseInt(question.type))">
                <RadioButton
                    v-bind:question="question"
                    v-bind:show_correct_or_wrong_answer="show_correct_or_wrong_answer"
                    v-bind:results="results"
                    v-on:toggleSubmitButton="toggleSubmitButton($event)"
                ></RadioButton>
              </template>

              <template v-if="question.type === 2">
                <CheckboxButton
                    v-bind:question="question"
                    v-bind:results="results"
                    v-on:toggleSubmitButton="toggleSubmitButton($event)"
                ></CheckboxButton>
              </template>

            </template>
          </template>
        </template>
      </div>

      <div v-if="show_next_button" class="submit-question">
        <NextButton
            v-bind:next_button_html="quiz.general_information.next_button_html"
            v-on:submitQuestion="submitQuestion()"
        ></NextButton>
      </div>

      <template v-for="(result_page, index) in quiz.result_pages" v-bind:key="result_page">
        <template v-if="current_result_page === index">
          <QuizResultPage
              v-bind:content_html="result_page.content_html"
          ></QuizResultPage>
        </template>
      </template>

      <div v-if="finished_quiz" class="socials">

        <template v-if="quiz.result_pages[current_result_page].share_quote">
          <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + parent_url + '&quote=' + quiz.result_pages[current_result_page].share_quote + ''" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 14222 14222"><circle cx="7111" cy="7112" r="7111" fill="#1977f3"/><path d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z" fill="#fff"/></svg>
          </a>
          <a v-bind:href="'https://twitter.com/share?url=' + parent_url + '&text=' + quiz.result_pages[current_result_page].share_quote" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="126.444 2.281 589 589"><circle cx="420.944" cy="296.781" r="294.5" fill="#2daae1"/><path d="M609.773 179.634c-13.891 6.164-28.811 10.331-44.498 12.204 16.01-9.587 28.275-24.779 34.066-42.86a154.78 154.78 0 0 1-49.209 18.801c-14.125-15.056-34.267-24.456-56.551-24.456-42.773 0-77.462 34.675-77.462 77.473 0 6.064.683 11.98 1.996 17.66-64.389-3.236-121.474-34.079-159.684-80.945-6.672 11.446-10.491 24.754-10.491 38.953 0 26.875 13.679 50.587 34.464 64.477a77.122 77.122 0 0 1-35.097-9.686v.979c0 37.54 26.701 68.842 62.145 75.961-6.511 1.784-13.344 2.716-20.413 2.716-4.998 0-9.847-.473-14.584-1.364 9.859 30.769 38.471 53.166 72.363 53.799-26.515 20.785-59.925 33.175-96.212 33.175-6.25 0-12.427-.373-18.491-1.104 34.291 21.988 75.006 34.824 118.759 34.824 142.496 0 220.428-118.052 220.428-220.428 0-3.361-.074-6.697-.236-10.021a157.855 157.855 0 0 0 38.707-40.158z" fill="#fff"/></svg>
          </a>
          <a v-bind:href="'https://t.me/share/url?url=' + parent_url + '&text=' + quiz.result_pages[current_result_page].share_quote" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 240 240"><defs><linearGradient id="a" x1=".667" x2=".417" y1=".167" y2=".75"><stop offset="0" stop-color="#37aee2"/><stop offset="1" stop-color="#1e96c8"/></linearGradient><linearGradient id="b" x1=".66" x2=".851" y1=".437" y2=".802"><stop offset="0" stop-color="#eff7fc"/><stop offset="1" stop-color="#fff"/></linearGradient></defs><circle cx="120" cy="120" r="120" fill="url(#a)"/><path fill="#c8daea" d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80"/><path fill="#a9c9dd" d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035"/><path fill="url(#b)" d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258"/></svg>
          </a>
        </template>

        <template v-if="!quiz.result_pages[current_result_page].share_quote">
          <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + parent_url + ''" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 14222 14222"><circle cx="7111" cy="7112" r="7111" fill="#1977f3"/><path d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z" fill="#fff"/></svg>
          </a>
          <a v-bind:href="'https://twitter.com/share?url=' + parent_url" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="126.444 2.281 589 589"><circle cx="420.944" cy="296.781" r="294.5" fill="#2daae1"/><path d="M609.773 179.634c-13.891 6.164-28.811 10.331-44.498 12.204 16.01-9.587 28.275-24.779 34.066-42.86a154.78 154.78 0 0 1-49.209 18.801c-14.125-15.056-34.267-24.456-56.551-24.456-42.773 0-77.462 34.675-77.462 77.473 0 6.064.683 11.98 1.996 17.66-64.389-3.236-121.474-34.079-159.684-80.945-6.672 11.446-10.491 24.754-10.491 38.953 0 26.875 13.679 50.587 34.464 64.477a77.122 77.122 0 0 1-35.097-9.686v.979c0 37.54 26.701 68.842 62.145 75.961-6.511 1.784-13.344 2.716-20.413 2.716-4.998 0-9.847-.473-14.584-1.364 9.859 30.769 38.471 53.166 72.363 53.799-26.515 20.785-59.925 33.175-96.212 33.175-6.25 0-12.427-.373-18.491-1.104 34.291 21.988 75.006 34.824 118.759 34.824 142.496 0 220.428-118.052 220.428-220.428 0-3.361-.074-6.697-.236-10.021a157.855 157.855 0 0 0 38.707-40.158z" fill="#fff"/></svg>
          </a>
          <a v-bind:href="'https://t.me/share/url?url=' + parent_url" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 240 240"><defs><linearGradient id="a" x1=".667" x2=".417" y1=".167" y2=".75"><stop offset="0" stop-color="#37aee2"/><stop offset="1" stop-color="#1e96c8"/></linearGradient><linearGradient id="b" x1=".66" x2=".851" y1=".437" y2=".802"><stop offset="0" stop-color="#eff7fc"/><stop offset="1" stop-color="#fff"/></linearGradient></defs><circle cx="120" cy="120" r="120" fill="url(#a)"/><path fill="#c8daea" d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80"/><path fill="#a9c9dd" d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035"/><path fill="url(#b)" d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258"/></svg>
          </a>
        </template>

        <a @click="draugiemShare()">
          <div class="draugiem-circle">
            <img width="25" src="@/assets/images/draugiem.png">
          </div>
        </a>
      </div>
    </div>

    <div class="for-height">
      <div class="height-block">
        <div v-html="quiz.general_information.intro_html"></div>
        <div v-html="quiz.general_information.next_button_html"></div>
      </div>

      <template v-if="quiz.general_information.type !== 2">
        <div v-for="(question, index) in quiz.questions" v-bind:key="index" class="height-block">
          <div v-html="question.content_html"></div>
          <div v-for="(answer, index) in question.answers" :key="index">
            <div v-html="answer.content_html"></div>
          </div>
          <div v-html="quiz.general_information.next_button_html"></div>
        </div>
        <div v-for="(result_page, index) in quiz.result_pages" v-bind:key="index" class="height-block">
          <div v-html="result_page.content_html" style="padding-bottom: 70px;"></div>
        </div>
      </template>

      <template v-if="quiz.general_information.type === 2">
        <div v-for="(question, index) in quiz.questions" v-bind:key="index" class="height-block">
          <div v-html="question.content_html"></div>
          <div v-for="(answer, index) in question.answers" :key="index">
            <div v-html="answer.content_html"></div>
          </div>
          <div v-for="(result_page, index) in quiz.result_pages" v-bind:key="index">
            <div v-html="result_page.content_html" style="padding-bottom: 70px;"></div>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import RadioButton from "./question-types/RadioButton";
import CheckboxButton from "./question-types/CheckboxButton";
import NextButton from "./NextButton";
import QuizResultPage from "./QuizResultPage";
import {mapState} from "vuex";

export default {
  name: 'Quiz',
  components: {QuizResultPage, NextButton, CheckboxButton, RadioButton},
  data() {
    return {
      parent_url: null,
      finished_quiz: false,
      quiz_height: null,
      current_question: 0,
      correctly_answered: 0,
      current_result_page: null,
      show_next_button: false,
      show_correct_or_wrong_answer: {
        status: false,
        wrong: null,
        correct: null
      },
      results: {
        status: false,
        answers: []
      },
      submitted_questions: [],
      temporary_answers: [],
      submitted_answers_and_questions: [],
      quiz: {
        general_information: {
          quiz_id: null,
          name: null,
          status: null,
          type: null,
          limited: null,
          intro_status: null,
          intro_html: null,
          next_button_html: null
        },
        questions: [],
        result_pages: []
      }
    }
  },
  computed: {
    ...mapState(['finished_quizzes'])
  },
  mounted() {
    this.parent_url = location.href.split('?parent-url=')[1]
    if (!this.parent_url) {
      this.parent_url = location.href
    }
    this.fetchQuiz()
    window.setInterval(() => {
      this.getQuizHeight()
    }, 500)
  },
  watch: {
    quiz_height: function (value) {
      window.top.postMessage({
        height: value,
        id: this.quiz.general_information.quiz_id
      }, '*');
    }
  },
  methods: {
    getQuizHeight() {
      this.$nextTick(() => {
        const height_blocks = document.getElementsByClassName('height-block');
        let height = 0;
        for (let height_block of height_blocks) {
          const block_height = height_block.clientHeight
          if (height < block_height) {
            height = block_height
          }
        }
        this.quiz_height = height
      });
    },
    fetchQuiz() {
      const route = useRoute()
      this.quiz.general_information.quiz_id = route.params.id
      this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/public', {
        quiz_id: this.quiz.general_information.quiz_id
      }, {})
          .then(response => {
            if (response.data.status === true) {
              this.quiz.general_information = {
                quiz_id: response.data.quiz.general_information.quiz_id,
                name: response.data.quiz.general_information.name,
                status: response.data.quiz.general_information.status,
                type: response.data.quiz.general_information.type,
                limited: response.data.quiz.general_information.limited,
                intro_status: response.data.quiz.general_information.intro_status,
                intro_html: response.data.quiz.general_information.intro_html,
                next_button_html: response.data.quiz.general_information.next_button_html
              }
              for (let question of response.data.quiz.questions) {
                this.quiz.questions.push({
                  question_id: question.question_id,
                  content_html: question.content_html,
                  correct_answer_content_html: question.correct_answer_content_html,
                  wrong_answer_content_html: question.wrong_answer_content_html,
                  type: question.type,
                  answers: question.answers
                })
              }
              if (response.data.quiz.result_pages !== undefined) {
                for (let result_page of response.data.quiz.result_pages) {
                  this.quiz.result_pages.push({
                    result_page_id: result_page.result_page_id,
                    content_html: result_page.content_html,
                    points_min: result_page.points_min,
                    points_max: result_page.points_max,
                    link_parameter: result_page.link_parameter,
                    share_quote: result_page.share_quote
                  })
                }
              }
              if (this.quiz.general_information.limited === 1) {
                for (let finished_quiz of this.finished_quizzes) {
                  if (parseInt(finished_quiz.quiz_id) === parseInt(this.quiz.general_information.quiz_id)) {
                    this.getFinishedQuiz(finished_quiz.finished_quiz_id)
                  }
                }
              }
              this.getQuizHeight()
            }
          })
    },
    toggleSubmitButton(data) {
      this.temporary_answers = []
      this.show_next_button = data.status
      for (let answer of data.answers) {
        this.temporary_answers.push(answer)
      }
      this.getQuizHeight()
    },
    submitQuestion() {
      if (this.quiz.general_information.type === 3) {
        if (this.show_correct_or_wrong_answer.status === false) {
          if (this.temporary_answers[0].correct_answer === 1) {
            this.correctly_answered++
            this.show_correct_or_wrong_answer.status = true
            this.show_correct_or_wrong_answer.correct = true
          } else {
            this.show_correct_or_wrong_answer.status = true
            this.show_correct_or_wrong_answer.wrong = true
          }
        } else {
          this.show_correct_or_wrong_answer.status = false
          this.show_correct_or_wrong_answer.correct = false
          this.show_correct_or_wrong_answer.wrong = false
          this.getNextQuestion()
        }
      } else if (this.quiz.general_information.type === 2) {
        this.submitSecondTypeQuizQuestion(true)
      } else if (this.quiz.general_information.type === 1 || this.quiz.general_information.type === 4) {
        this.getNextQuestion()
      }
      this.getQuizHeight()
    },
    submitSecondTypeQuizQuestion(submit) {
      if (this.results.status === false) {
        this.results.status = true
        this.show_next_button = false
        if (submit) {
          for (let temporary_answer of this.temporary_answers) {
            this.submitted_answers_and_questions.push(temporary_answer)
          }
          this.submitQuiz()
        } else {
          this.getResultPage()
        }
      }
    },
    getNextQuestion() {
      let possible_next_questions = []
      for (let temporary_answer of this.temporary_answers) {
        possible_next_questions.push(temporary_answer.next_question_number)
        this.submitted_answers_and_questions.push(temporary_answer)
      }
      if (possible_next_questions.length !== 0) {
        if (possible_next_questions.includes(null)) {
          this.current_question++
        } else {
          this.current_question = Math.min(...possible_next_questions) - 1
        }
      } else {
        this.current_question++
      }
      this.submitted_questions.push(this.temporary_answers)
      this.temporary_answers = []
      this.show_next_button = false
      if (this.quiz.questions.length === this.current_question) {
        this.getResultPage()
      }
    },
    getResultPage() {
      if (this.quiz.general_information.type === 1) {
        let all_result_page_numbers = [];
        for (let submitted_question of this.submitted_questions) {
          for (let submitted_answer of submitted_question) {
            let result_page_ids = submitted_answer.result_page_numbers.split(',')
            for (let result_page_id of result_page_ids) {
              all_result_page_numbers.push(parseInt(result_page_id))
            }
          }
        }
        const counts = [];
        all_result_page_numbers.forEach(function (x) {
          counts[x] = (counts[x] || 0) + 1;
        })
        const self = this
        const sorted_counts = counts.map(function (el, i) {
          return {index: i, value: el}
        })
        sorted_counts.sort((a, b) => a.value < b.value && 1 || -1)
        sorted_counts.forEach(function (x) {
          if (self.current_result_page === null) {
            self.current_result_page = x.index - 1
          }
        })
      } else if (this.quiz.general_information.type === 2) {
        this.current_result_page = 0
      } else if (this.quiz.general_information.type === 3) {
        this.current_result_page = this.correctly_answered
      } else if (this.quiz.general_information.type === 4) {
        let points = 0;
        for (let submitted_question of this.submitted_questions) {
          for (let submitted_answer of submitted_question) {
            points = points + submitted_answer.result_page_points;
          }
        }
        this.quiz.result_pages.forEach((value, index) => {
          if (value.points_min <= points && value.points_max >= points) {
            this.current_result_page = index
          }
        })
      }
      if (!this.finished_quiz) {
        this.submitQuiz()
      }
    },
    submitQuiz() {
      this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/submit', {
        quiz_id: this.quiz.general_information.quiz_id,
        answers: this.submitted_answers_and_questions
      }, {})
          .then(response => {
            if (response.data.status) {
              this.finished_quiz = true
              if (this.quiz.general_information.limited === 1) {
                this.$store.commit('updateFinishedQuizzes', {
                  'quiz_id': this.quiz.general_information.quiz_id,
                  'finished_quiz_id': response.data.finished_quiz_id
                })
              }
              if (this.quiz.general_information.type === 2) {
                this.results.answers = response.data.results
                this.current_result_page = 0
              }
              this.setLinkAttribute()
            }
          })
    },
    getFinishedQuiz(finished_quiz_id) {
      this.finished_quiz = true
      this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/finished', {
        quiz_id: this.quiz.general_information.quiz_id,
        finished_quiz_id: finished_quiz_id
      }, {})
          .then(response => {
            if (response.data.status === true) {
              if (this.quiz.general_information.type === 1 || this.quiz.general_information.type === 4) {
                this.submitted_questions.push(response.data.results)
                this.getResultPage()
              } else if (this.quiz.general_information.type === 2) {
                this.submitSecondTypeQuizQuestion(false)
                this.results.answers = response.data.results
              } else if (this.quiz.general_information.type === 3) {
                for (let answer of response.data.results) {
                  if (answer.correct_answer === 1) {
                    this.current_result_page++
                  }
                }
                if (!this.current_result_page) {
                  this.current_result_page = 0
                }
              }
            }
          })
    },
    draugiemShare() {
      window.open('https://www.draugiem.lv/say/ext/add.php?title=' + this.quiz.general_information.name + '&link=' + this.parent_url + '&titlePrefix=' + this.parent_url + '', 'popUpWindow', 'height=500, width=400, resizable=yes, scrollbars=yes, toolbar=yes, menubar=no, location=no, directories=no, status=yes')
    },
    setLinkAttribute() {
      if (this.quiz.result_pages[this.current_result_page].link_parameter !== null) {
        window.top.postMessage({
          link_parameter: this.quiz.result_pages[this.current_result_page].link_parameter
        }, '*');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include publicQuiz;

.for-height {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -9999;
}
</style>
