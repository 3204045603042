<template>
  <template v-if="show_correct_or_wrong_answer.status">

    <template v-if="show_correct_or_wrong_answer.correct">
      <div v-html="question.correct_answer_content_html"></div>
    </template>

    <template v-if="show_correct_or_wrong_answer.wrong">
      <div v-html="question.wrong_answer_content_html"></div>
    </template>

  </template>
  <template v-else>

    <div v-html="question.content_html"></div>
    <div class="radio-answers">
      <div v-for="(answer, index) in question.answers" :key="answer" class="answer">
        <input v-if="!results.status" type="radio" :id="answer.answer_id" :value="answer.answer_id" :name="question.question_id" v-on:change="radioButtonChange(answer)" class="radio-input">
        <label :for="answer.answer_id" v-html="answer.content_html"></label>
        <div v-if="results.status" class="results" v-html="results.answers[index]"></div>
      </div>
    </div>

  </template>
</template>

<script>
export default {
  name: 'RadioButton',
  props: ['question', 'show_correct_or_wrong_answer', 'results'],
  emits: ['toggleSubmitButton'],
  mounted() {
    this.setRadioInputPosition()
    window.addEventListener('resize', this.setRadioInputPosition)

  },
  updated() {
    this.setRadioInputPosition()
    window.addEventListener('resize', this.setRadioInputPosition)
  },
  methods: {
    radioButtonChange(answer) {
      this.$emit('toggleSubmitButton', {
        status: true,
        answers: [answer]
      })
    },
    setRadioInputPosition() {
      const all_answers = document.querySelectorAll('.answer')
      for (let i = 0; i < all_answers.length; i++) {
        const left_position = all_answers[i].querySelector('.u_body .u_row .container').getBoundingClientRect().left
        const input = all_answers[i].querySelector('.radio-input')
        if (input !== null) {
          input.style.left = left_position + 19 + 'px'
        }
        const results = all_answers[i].querySelector('.results')
        if (results !== null) {
          results.style.left = left_position + 5 + 'px'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include publicQuiz;
</style>
