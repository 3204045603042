<template>
  <div class="main-menu-wrapper">
    <div class="logo-container">
      <a href="#" @click="goToDashboard">
        <img src="@/assets/images/company-logo.png">
      </a>
    </div>
    <div class="main-menu" v-bind:class="{'is-active': active}">
      <ul>
        <a href="#" @click="goToDashboard">
          <li v-bind:class="{'active': $route.name === 'Dashboard'}">
            <i class="far fa-chart-bar"></i>
            <span class="dash">SĀKUMS</span>
          </li>
        </a>
        <a href="#" @click="goToQuizzes">
          <li v-bind:class="{'active': $route.name === 'QuizzesList' || $route.name === 'QuizzesAddForm' || $route.name === 'QuizzesEditForm' || $route.name === 'QuizzesResults'}">
            <i class="far fa-question-circle"></i>
            <span class="dash">APTAUJAS</span>
          </li>
        </a>
        <a href="#" @click="goToUsers">
          <li v-bind:class="{'active': $route.name === 'Users' || $route.name === 'EditUser' || $route.name === 'NewUser'}">
            <i class="far fa-user"></i>
            <span class="dash">ADMINISTRATORI</span>
          </li>
        </a>
      </ul>
    </div>
    <button class="hamburger hamburger--collapse" v-bind:class="{'is-active': active}" type="button" @click="toggleMobileMenu">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
</template>

<script>
import 'hamburgers/dist/hamburgers.min.css'

export default {
  name: 'MainMenu',
  data() {
    return {
      active: false
    }
  },
  methods: {
    toggleMobileMenu() {
      this.active = !this.active;
    },
    goToDashboard() {
      this.$router.push('/dashboard')
    },
    goToQuizzes() {
      this.$router.push('/quizzes/list')
    },
    goToUsers() {
      this.$router.push('/users/list')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

.main-menu-wrapper {
  flex-grow: 0;
  min-height: 100vh;
  width: 250px;
  background-color: #fff;
  padding: 20px 10px;

  .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    border-bottom: 1px solid $grey-accent;
    padding-bottom: 10px;

    a {
      transition: 0.3s;

      img {
        width: 135px;
        height: auto;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    @media only screen and (max-width: $medium-breakpoint) {
      display: none;
    }
  }

  .main-menu {
    ul {
      padding-left: 0;
      list-style: none;

      a {
        font-size: 16px;
        color: $red-accent;
        text-decoration: none;
        font-weight: bold;

        li {
          padding: 10px;
          margin-bottom: 5px;
          transition: 0.5s;

          i {
            margin-right: 5px;
          }

          &.active {
            background: $red-accent;
            color: #fff;

            i {
              color: #fff;
            }

            &:hover {
              background: $red-accent;
            }
          }
        }

        &:hover {
          li {
            background: #faced9;
          }
        }

        @media only screen and (max-width: $large-breakpoint) {
          margin-right: 5px;
          display: inherit;
        }

        @media only screen and (max-width: $mini-breakpoint) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: $medium-breakpoint) {
        flex-direction: column;
        width: 100%;
      }
    }

    &.is-active {
      width: 100%;
      height: auto;
      align-items: center;
      padding: 5px;

      @media only screen and (max-width: $large-breakpoint) {
        display: flex;
      }

      @media only screen and (max-width: $medium-breakpoint) {
        position: absolute;
        background: #fff;
        top: 2px;
        margin-top: 40px;
      }
    }

    @media only screen and (max-width: $medium-breakpoint) {
      display: none;
    }
  }

  .hamburger {
    display: none;
    position: absolute;
    top: 42px;
    right: 0;
    z-index: 2;

    &.hamburger--collapse {
      .hamburger-box {
        .hamburger-inner {
          background-color: #e70e44;

          &::before {
            background-color: #e70e44;
          }

          &::after {
            background-color: #e70e44;
          }
        }
      }
    }

    &.active {
      top: 7px;

      .hamburger-box {
        .hamburger-inner {
          background-color: #e70e44;

          &::before {
            background-color: #e70e44;
          }

          &::after {
            background-color: #e70e44;
          }
        }
      }
    }

    @media only screen and (max-width: $medium-breakpoint) {
      display: block;
      left: 0;
      top: -7px;
    }
  }

  @media only screen and (max-width: $medium-breakpoint) {
    display: flex;
    align-items: center;
    min-height: 0;
    padding: 0;
    width: 100%;
    z-index: 99999;
  }

  @media only screen and (max-width: $small-breakpoint) {
    flex-direction: column;
  }
}
</style>
