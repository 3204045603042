<template>
  <div class="page-loader-container">
    <div class="page-loader"></div>
    <div class="page-loader-items">
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLoader',
}
</script>

<style lang="scss" scoped>
$colors: #eee, #e70e43, #eee, #e70e43;

.page-loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #333;
  opacity: 0.7;
  z-index: 9999999;
}

.page-loader-items {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
}

.cube {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  @for $i from 1 through length($colors) {
    &:nth-child(#{$i}) {
      background-color: nth($colors, $i);
    }
  }

  &:first-child {
    animation: left 1s infinite;
  }

  &:last-child {
    animation: right 1s infinite .5s;
  }
}

@keyframes left {
  40% {
    transform: translateX(-60px);
  }
  50% {
    transform: translateX(0);
  }
}

@keyframes right {
  40% {
    transform: translateX(60px);
  }
  50% {
    transform: translateX(0);
  }
}
</style>
