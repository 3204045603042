<template>
  <div v-html="button" @click="submitQuestion()"></div>
</template>

<script>
export default {
  name: 'NextButton',
  props: ['next_button_html'],
  emits: ['submitQuestion'],
  data() {
    return {
      button: null
    }
  },
  watch: {
    next_button_html: {
      immediate: true,
      handler: function (data) {
        this.button = data
      }
    }
  },
  methods: {
    submitQuestion() {
      this.$emit('submitQuestion')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>