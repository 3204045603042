<template>
  <form @submit.prevent="submit" autocomplete="off" spellcheck="false">

    <General
        v-bind:old_general_information="quiz.general_information"
        v-on:updateGeneral="updateGeneral($event)"
    ></General>

    <QuestionAndAnswers
        v-for="(question, index) in quiz.questions" v-bind:key="question"
        v-bind:counter="index"
        v-bind:old_question="question"
        v-bind:quiz="quiz"
        v-on:updateQuestionContent="updateQuestionContent($event, index)"
        v-on:updateQuestionType="updateQuestionType($event, index)"
        v-on:updateQuestionAnswers="updateQuestionAnswers($event, index)"
        v-on:deleteQuestion="deleteQuestion(index)"
        v-on:duplicateQuestion="duplicateQuestion(index)"
    ></QuestionAndAnswers>

    <ResultPage
        v-for="(result_page, index) in quiz.result_pages" v-bind:key="result_page"
        v-bind:counter="index"
        v-bind:old_result_page="result_page"
        v-bind:quiz="quiz"
        v-on:updateResultPageContent="updateResultPageContent($event, index)"
        v-on:deleteResultPage="deleteResultPage(index)"
        v-on:duplicateResultPage="duplicateResultPage(index)"
    ></ResultPage>

    <div class="block">
      <div class="button-block">
        <button @click.prevent="addQuestion" v-if="(parseInt(quiz.general_information.type) === 2 && quiz.questions.length < 1) || parseInt(quiz.general_information.type) !== 2">
          <i class="fa fa-plus"></i>
          <span class="button-name">Pievienot jautājumu</span>
        </button>
        <button @click.prevent="addResultPage" v-if="(parseInt(quiz.general_information.type) === 2 && quiz.result_pages.length < 1) || parseInt(quiz.general_information.type) !== 2">
          <i class="fa fa-plus"></i>
          <span class="button-name">Pievienot rezultātu lapu</span>
        </button>
        <button>
          <i class="far fa-save"></i>
          <span class="button-name">Saglabāt</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import General from './form-blocks/General'
import QuestionAndAnswers from './form-blocks/QuestionAndAnswers'
import {useRoute} from 'vue-router';
import ResultPage from './form-blocks/ResultPage';

export default {
  name: 'Form',
  components: {ResultPage, QuestionAndAnswers, General},
  data() {
    return {
      warn_before_leave: true,
      quiz_id: null,
      quiz: {
        general_information: {},
        questions: [],
        result_pages: []
      }
    }
  },
  mounted() {
    const route = useRoute()
    this.quiz_id = route.params.id
    if (this.quiz_id) {
      this.loadQuiz()
    }
  },
  methods: {
    updateGeneral(general) {
      this.quiz.general_information = general
    },
    updateQuestionContent(question, index) {
      this.quiz.questions[index].content_html = question.content_html
      this.quiz.questions[index].content_json = question.content_json
      this.quiz.questions[index].correct_answer_content_json = question.correct_answer_content_json
      this.quiz.questions[index].correct_answer_content_html = question.correct_answer_content_html
      this.quiz.questions[index].wrong_answer_content_json = question.wrong_answer_content_json
      this.quiz.questions[index].wrong_answer_content_html = question.wrong_answer_content_html
    },
    updateQuestionType(type, index) {
      this.quiz.questions[index].type = type
    },
    updateQuestionAnswers(answers, index) {
      this.quiz.questions[index].answers = answers;
    },
    addQuestion() {
      this.quiz.questions.push({
        content_html: null,
        content_json: null,
        correct_answer_content_json: null,
        correct_answer_content_html: null,
        wrong_answer_content_json: null,
        wrong_answer_content_html: null,
        type: null,
      });
    },
    addResultPage() {
      this.quiz.result_pages.push({
        content_html: null,
        content_json: null
      });
    },
    deleteQuestion(index) {
      this.quiz.questions.splice(index, 1)
    },
    duplicateQuestion(index) {
      let duplicate = this.quiz.questions[index]
      duplicate.question_id = null
      let duplicated_answers = []
      if (duplicate.answers) {
        for (let answer of duplicate.answers) {
          duplicated_answers.push({
            answer_id: null,
            correct_answer: answer.correct_answer,
            content_html: answer.content_html,
            content_json: answer.content_json,
            result_page_numbers: answer.result_page_numbers,
            next_question_number: answer.next_question_number
          });
          answer.answer_id = null
        }
      }
      this.quiz.questions.push({
        question_id: null,
        content_html: duplicate.content_html,
        content_json: duplicate.content_json,
        correct_answer_content_json: duplicate.correct_answer_content_json,
        correct_answer_content_html: duplicate.correct_answer_content_html,
        wrong_answer_content_json: duplicate.wrong_answer_content_json,
        wrong_answer_content_html: duplicate.wrong_answer_content_html,
        type: duplicate.type,
        answers: duplicated_answers
      })
    },
    duplicateResultPage(index) {
      let duplicate = this.quiz.result_pages[index]
      this.quiz.result_pages.push({
        result_page_id: null,
        content_html: duplicate.content_html,
        content_json: duplicate.content_json,
        points_min: duplicate.points_min,
        points_max: duplicate.points_max,
        link_parameter: duplicate.link_parameter,
        share_quote: duplicate.share_quote
      })
    },
    updateResultPageContent(result_page, index) {
      this.quiz.result_pages[index].content_html = result_page.content_html
      this.quiz.result_pages[index].content_json = result_page.content_json
    },
    deleteResultPage(index) {
      this.quiz.result_pages.splice(index, 1)
    },
    loadQuiz() {
      this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/get', {
        quiz_id: this.quiz_id
      }, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      })
          .then(response => {
            if (response.data.status === true) {
              this.quiz.general_information = {
                name: response.data.quiz.general_information.name,
                type: response.data.quiz.general_information.type,
                status: response.data.quiz.general_information.status,
                limited: response.data.quiz.general_information.limited,
                intro_status: response.data.quiz.general_information.intro_status,
                intro_html: response.data.quiz.general_information.intro_html,
                intro_json: response.data.quiz.general_information.intro_json,
                next_button_html: response.data.quiz.general_information.next_button_html,
                next_button_json: response.data.quiz.general_information.next_button_json,
              }
              for (let question of response.data.quiz.questions) {
                this.quiz.questions.push({
                  question_id: question.question_id,
                  content_html: question.content_html,
                  content_json: question.content_json,
                  correct_answer_content_json: question.correct_answer_content_json,
                  correct_answer_content_html: question.correct_answer_content_html,
                  wrong_answer_content_json: question.wrong_answer_content_json,
                  wrong_answer_content_html: question.wrong_answer_content_html,
                  type: question.type,
                  answers: question.answers
                })
              }
              if (response.data.quiz.result_pages !== undefined) {
                for (let result_page of response.data.quiz.result_pages) {
                  this.quiz.result_pages.push({
                    result_page_id: result_page.result_page_id,
                    content_html: result_page.content_html,
                    content_json: result_page.content_json,
                    points_min: result_page.points_min,
                    points_max: result_page.points_max,
                    link_parameter: result_page.link_parameter,
                    share_quote: result_page.share_quote
                  })
                }
              }
            }
          })
    },
    submit() {
      if (this.valid()) {
        this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/create', {
          quiz_id: this.quiz_id,
          quiz: this.quiz
        }, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.token
          }
        })
            .then(response => {
              if (response.data.status === true) {
                this.warn_before_leave = false
                this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastSuccess)
                this.$router.push('/quizzes/list')
              } else {
                this.$moshaToast('Kaut kas nogāja greizi. Mēģini vēlreiz vai sazinies ar lapas administratoru!', this.$store.state.toast.moshaToastDanger)
              }
            })
      }
    },
    valid() {
      let status = true
      if (this.quiz.general_information.intro_status === null) {
        this.$moshaToast('Norādiet vai aptaujai nepieciešama sākumlapa!', this.$store.state.toast.moshaToastDanger)
        status = false
      }
      if (this.quiz.general_information.limited === null) {
        this.$moshaToast('Norādiet aptaujas aizpildīšanas ierobežojumus!', this.$store.state.toast.moshaToastDanger)
        status = false
      }
      if (!this.quiz.general_information.type) {
        this.$moshaToast('Norādiet aptaujas tipu!', this.$store.state.toast.moshaToastDanger)
        status = false
      } else {
        if (this.quiz.questions.length === 0) {
          this.$moshaToast('Pievienojiet vismaz vienu jautājumu!', this.$store.state.toast.moshaToastDanger)
          status = false
        } else {
          for (let question of this.quiz.questions) {
            if (question.answers === undefined || question.answers.length === 0) {
              this.$moshaToast('Norādi vismaz vienu atbildi katram jautājumam!', this.$store.state.toast.moshaToastDanger)
              status = false
            }
            if (!question.type) {
              this.$moshaToast('Norādi jautājuma tipu!', this.$store.state.toast.moshaToastDanger)
              status = false
            }
          }
        }
        if (parseInt(this.quiz.general_information.type) !== 2 && this.quiz.result_pages.length === 0) {
          this.$moshaToast('Pievieno vismaz vienu rezultātu lapu!', this.$store.state.toast.moshaToastDanger)
          status = false
        }
      }
      if (!this.quiz.general_information.status) {
        this.$moshaToast('Norādiet aptaujas statusu!', this.$store.state.toast.moshaToastDanger)
        status = false
      }
      if (!this.quiz.general_information.name) {
        this.$moshaToast('Ievadiet aptaujas nosaukumu!', this.$store.state.toast.moshaToastDanger)
        status = false
      }
      return status
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.warn_before_leave) {
      if (window.confirm('Vai Jūs tiešām vēlaties pamest šo lapu?')) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include block;

@include inputBlock;

@include buttonBlock(flex-end)
</style>
