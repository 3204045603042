<template>
  404
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "@/assets/styles/_includes.scss";
</style>
