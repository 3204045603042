import {createRouter, createWebHistory} from 'vue-router'
import Login from '@/components/pages/Login'
import Dashboard from '@/components/pages/Dashboard'
import Profile from '@/components/pages/Profile'
import NotFound from '@/components/pages/NotFound'
import List from '@/components/quizzes/List'
import Form from '@/components/quizzes/Form'
import Show from '@/components/public-quizzes/Quiz'
import store from '@/store'
import Users from '@/components/users/Users'
import UserForm from '@/components/users/UserForm'
import Results from "../components/quizzes/Results";

const routes = [
    {
        path: '/:catchAll(.*)',
        component: NotFound,
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/quizzes/list',
        name: 'QuizzesList',
        component: List,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/quizzes/new',
        name: 'QuizzesAddForm',
        component: Form,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/quizzes/edit/:id',
        name: 'QuizzesEditForm',
        component: Form,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/quizzes/results/:id',
        name: 'QuizzesResults',
        component: Results,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/quizzes/show/:id',
        name: 'PublicQuiz',
        component: Show,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/users/list',
        name: 'Users',
        component: Users,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users/edit/:id',
        name: 'EditUser',
        component: UserForm,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users/new',
        name: 'NewUser',
        component: UserForm,
        meta: {
            requiresAuth: true
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(route => route.meta.requiresAuth)){
        if(store.state.token) return next()
        return next('/')
    }
    next();
});

export default router
