<template>
  <form @submit.prevent="submit" autocomplete="off" spellcheck="false">
    <div class="block">
      <div class="block-title">
        <h1>Labot profilu</h1>
      </div>
      <div class="input-block">
        <label>Vārds:</label>
        <input type="text" v-model="name">
      </div>
      <div class="input-block">
        <label>Uzvārds:</label>
        <input type="text" v-model="surname">
      </div>
      <div class="input-block">
        <label>E-pasta adrese:</label>
        <input type="email" v-model="email">
      </div>
      <div class="input-block">
        <label>Parole:</label>
        <input type="password" v-model="password">
      </div>
      <div class="input-block">
        <label>Vēlreiz parole:</label>
        <input type="password" v-model="password_repeat">
      </div>
      <div class="button-block">
        <button>
          <i class="far fa-save"></i>
          <span class="button-name">Saglabāt</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: 'Profile',
  data() {
    return {
      name: this.$store.state.user.name,
      surname: this.$store.state.user.surname,
      email: this.$store.state.user.email,
      password: '',
      password_repeat: ''
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    submit() {
      if (this.name === '') {
        this.$moshaToast("Ievadi vārdu!", this.$store.state.toast.moshaToastDanger)
      } else if (this.surname === '') {
        this.$moshaToast("Ievadi uzvārdu!", this.$store.state.toast.moshaToastDanger)
      } else if (this.email === '') {
        this.$moshaToast("Ievadi e-pastu!", this.$store.state.toast.moshaToastDanger)
      } else if (this.password !== '' && this.password !== this.password_repeat) {
        this.$moshaToast("Paroles nesakrīt!", this.$store.state.toast.moshaToastDanger)
      } else {
        this.axios.post(process.env.VUE_APP_API_LINK + '/profile/update', {
          name: this.name,
          surname: this.surname,
          email: this.email,
          password: this.password
        }, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.token
          }
        })
            .then(response => {
              if (response.data.status === true) {
                this.$store.commit('updateProfile', response.data.user)
                this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastSuccess)
              } else {
                this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastDanger)
              }
            })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include block;

@include inputBlock;

@include buttonBlock(flex-end);
</style>
