import {createStore} from "vuex"
import createPersistedState from "vuex-persistedstate"
import axios from "axios";

const store = createStore({
    state() {
        return {
            token: null,
            user: {
                name: null,
                surname: null,
                email: null,
                id: null,
            },
            toast: {
                moshaToastDanger: {
                    type: 'danger',
                    transition: 'slide',
                    toastBackgroundColor: '#e70e44',
                    position: 'bottom-right'
                },
                moshaToastSuccess: {
                    type: 'success',
                    transition: 'slide',
                    position: 'bottom-right'
                }
            },
            quizzes: [],
            users: [],
            finished_quizzes: []
        }
    },
    mutations: {
        updateToken(state, token) {
            state.token = token
        },
        updateProfile(state, user) {
            state.user.name = user.name
            state.user.surname = user.surname
            state.user.email = user.email
            state.user.id = user.user_id
        },
        logoutUser(state) {
            state.token = null
            state.user.name = null
            state.user.surname = null
        },
        getAllQuizzes(state, quizzes) {
            state.quizzes = quizzes
        },
        getAllUsers(state, users) {
            state.users = users
        },
        updateFinishedQuizzes(state, finished_quiz) {
            state.finished_quizzes.push(finished_quiz)
        }
    },
    actions: {
        async fetchAllQuizzes(context) {
            axios.post(process.env.VUE_APP_API_LINK + '/quizzes/all', {}, {
                headers: {
                    Authorization: 'Bearer ' + this.state.token
                }
            })
                .then(response => {
                    if (response.data.status === true) {
                        context.commit('getAllQuizzes', response.data.quizzes)
                    }
                })
        },
        async fetchAllUsers(context) {
            axios.post(process.env.VUE_APP_API_LINK + '/users/all', {}, {
                headers: {
                    Authorization: 'Bearer ' + this.state.token
                }
            })
                .then(response => {
                    if (response.data.status === true) {
                        context.commit('getAllUsers', response.data.users)
                    }
                })
        }
    },
    plugins: [
        createPersistedState({storage: localStorage})
    ]
})

export default store;
