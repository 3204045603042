<template>
  <div class="block">
    <div class="button-block">
      <button @click="goToQuizForm">
        <i class="fa fa-plus"></i>
        <span class="button-name">Jauna aptauja</span>
      </button>
    </div>
    <table>
      <thead>
      <tr>
        <th>ID</th>
        <th>Nosaukums</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="quiz in quizzes" :key="quiz.id">
        <td>{{ quiz.id }}</td>
        <td>{{ quiz.name }}</td>
        <td>
          <div class="buttons">
            <a href="#" class="button js-tooltip-show" @click="openQuiz(quiz.id)">
              <i class="far fa-eye"></i>
            </a>
            <a href="#" class="button js-tooltip-copy" @click="copyIframe(quiz.id)">
              <i class="fas fa-code"></i>
            </a>
            <a href="#" class="button js-tooltip-results" @click="goToQuizResults(quiz.id)">
              <i class="far fa-chart-bar"></i>
            </a>
            <a href="#" class="button js-tooltip-edit" @click="goToQuizEditForm(quiz.id)">
              <i class="far fa-edit"></i>
            </a>
            <a href="#" class="button js-duplicate" @click="duplicateQuiz(quiz.id)">
              <i class="fas fa-copy"></i>
            </a>
            <a href="#" class="button js-tooltip-delete" @click="deleteQuiz(quiz.id)">
              <i class="far fa-trash-alt"></i>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapState} from "vuex"
import tippy from 'tippy.js'
import useClipboard from 'vue-clipboard3'
import 'tippy.js/dist/tippy.css'

export default {
  name: 'List',
  computed: {
    ...mapState(['quizzes'])
  },
  mounted() {
    this.$store.dispatch('fetchAllQuizzes')
    tippy('.js-tooltip-show', {
      content: 'Atvērt',
    });
    tippy('.js-tooltip-copy', {
      content: 'Kopēt embed kodu',
    });
    tippy('.js-tooltip-results', {
      content: 'Rezultāti',
    });
    tippy('.js-tooltip-edit', {
      content: 'Labot',
    });
    tippy('.js-tooltip-delete', {
      content: 'Dzēst',
    });
    tippy('.js-duplicate', {
      content: 'Dublicēt aptauju',
    });
  },
  methods: {
    goToQuizForm() {
      this.$router.push('/quizzes/new')
    },
    goToQuizEditForm(quiz_id) {
      this.$router.push('/quizzes/edit/' + quiz_id)
    },
    goToQuizResults(quiz_id) {
      this.$router.push('/quizzes/results/' + quiz_id)
    },
    copyIframe(quiz_id) {
      const quiz_route = window.location.origin + this.$router.resolve({
        name: 'PublicQuiz',
        params: {id: quiz_id}
      }).href
      const embed = '' +
          '<iframe scrolling="no" style="overflow: hidden; width: 100%; border: none;" id="quiz-' + quiz_id + '"></iframe>' +
          '<scr' + 'ipt>' +
          '  let iframe_url' + quiz_id + ' = "' + quiz_route + '?parent-url=" + window.location.href;' +
          '  document.getElementById("quiz-'+ quiz_id +'").setAttribute("src", iframe_url' + quiz_id + ');' +
          '  window.addEventListener("message", function (e) {' +
          '    if (e.data.height) {' +
          '      document.querySelector("#quiz-" + e.data.id).style.height = e.data.height + "px";' +
          '    }' +
          '    if (e.data.link_parameter) {' +
          '      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?result-page=" + e.data.link_parameter;' +
          '      window.history.pushState({path:newurl}, "", newurl);' +
          '    }' +
          '  }, false);' +
          '</scr' + 'ipt>'
      try {
        const {toClipboard} = useClipboard()
        toClipboard(embed)
        this.$moshaToast('Aptaujas embed kods ir veiksmīgi nokopēts!', this.$store.state.toast.moshaToastSuccess)
      } catch (error) {
        this.$moshaToast('Kaut kas nogāja greizi. Mēģini vēlreiz vai sazinies ar lapas administratoru!', this.$store.state.toast.moshaToastDanger)
      }
    },
    openQuiz(quiz_id) {
      const route = this.$router.resolve({path: '/quizzes/show/' + quiz_id})
      window.open(route.path, '_blank').focus();
    },
    deleteQuiz(quiz_id) {
      if (confirm('Vai Jūs tiešām vēlaties dzēst aptauju?')) {
        this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/delete', {
          quiz_id: quiz_id
        }, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.token
          }
        })
            .then(response => {
              if (response.data.status === true) {
                this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastSuccess)
                this.$store.dispatch('fetchAllQuizzes')
              } else {
                this.$moshaToast('Kaut kas nogāja greizi. Mēģini vēlreiz vai sazinies ar lapas administratoru!', this.$store.state.toast.moshaToastDanger)
              }
            })
      }
    },
    duplicateQuiz(quiz_id) {
      if (confirm('Vai Jūs tiešām vēlaties dublicēt aptauju?')) {
        this.axios.post(process.env.VUE_APP_API_LINK + '/quizzes/duplicate', {
          quiz_id: quiz_id
        }, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.token
          }
        })
            .then(response => {
              if (response.data.status === true) {
                this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastSuccess)
                this.$store.dispatch('fetchAllQuizzes')
              } else {
                this.$moshaToast('Kaut kas nogāja greizi. Mēģini vēlreiz vai sazinies ar lapas administratoru!', this.$store.state.toast.moshaToastDanger)
              }
            })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

body {
  font-family: $text-font;
}

@include block;

@include buttonBlock(flex-start);

.button-block {
  margin-bottom: 15px;
}

table {
  font-size: 16px;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid $red-accent;
}

table th {
  transition: 0.3s;
  text-align: left;
  background: $red-accent;
  color: #FFF;
  padding: 8px;
  min-width: 30px;
}

table td {
  text-align: left;
  padding: 8px;
}

table td:last-child, table td:first-child {
  border-right: none;
  width: 1px;
  white-space: nowrap;
}

table tbody tr:nth-child(2n) td {
  background: $red-hover;
}

.buttons {
  display: flex;
}

button, a {
  padding: 5px;
  height: 30px;
  min-width: 30px;
  text-decoration: none;
  font-size: 12px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $red-accent;
  cursor: pointer;
  background-color: $red-accent;
  background-repeat: no-repeat;
  transition: 0.3s;
  margin: 0 5px;

  i {
    color: #fff;
    transition: 0.3s;
  }

  &:hover {
    background-color: $red-hover;

    i {
      color: $red-accent;
    }
  }
}
</style>
