<template>
  <form @submit.prevent="submit" autocomplete="off" spellcheck="false">
    <div class="block">
      <div class="block-title">
        <h1 v-if="user_id">Labot administratoru</h1>
        <h1 v-else>Administratora informācija</h1>
      </div>
      <div class="input-block">
        <label>Vārds:</label>
        <input type="text" v-model="name">
      </div>
      <div class="input-block">
        <label>Uzvārds:</label>
        <input type="text" v-model="surname">
      </div>
      <div class="input-block">
        <label>E-pasta adrese:</label>
        <input type="email" v-model="email">
      </div>
      <div class="input-block">
        <label>Parole:</label>
        <input type="password" v-model="password">
      </div>
      <div class="input-block">
        <label>Vēlreiz parole:</label>
        <input type="password" v-model="password_repeat">
      </div>
      <div class="button-block">
        <button>
          <i class="far fa-save"></i>
          <span class="button-name" v-if="user_id">Saglabāt</span>
          <span class="button-name" v-else>Izveidot</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {useRoute} from "vue-router";

export default {
  name: 'UserForm',
  data() {
    return {
      user_id: null,
      name: null,
      surname: null,
      email: null,
      password: null,
      password_repeat: null
    }
  },
  methods: {
    submit() {
      if (this.name === '') {
        this.$moshaToast("Ievadi vārdu!", this.$store.state.toast.moshaToastDanger)
      } else if (this.surname === '') {
        this.$moshaToast("Ievadi uzvārdu!", this.$store.state.toast.moshaToastDanger)
      } else if (this.email === '') {
        this.$moshaToast("Ievadi e-pastu!", this.$store.state.toast.moshaToastDanger)
      } else if (this.password !== '' && this.password !== this.password_repeat) {
        this.$moshaToast("Paroles nesakrīt!", this.$store.state.toast.moshaToastDanger)
      } else {
        if (this.user_id) {
          this.axios.post(process.env.VUE_APP_API_LINK + '/users/update', {
            user_id: this.user_id,
            name: this.name,
            surname: this.surname,
            email: this.email,
            password: this.password
          }, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.token
            }
          })
              .then(response => {
                if (response.data.status === true) {
                  this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastSuccess)
                  this.$router.push('/users/list')
                } else {
                  this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastDanger)
                }
              })
        } else {
          if (this.name === null) {
            this.$moshaToast("Ievadi vārdu!", this.$store.state.toast.moshaToastDanger)
          } else if (this.surname === null) {
            this.$moshaToast("Ievadi uzvārdu!", this.$store.state.toast.moshaToastDanger)
          } else if (this.email === null) {
            this.$moshaToast("Ievadi e-pastu!", this.$store.state.toast.moshaToastDanger)
          } else if (this.password === null) {
            this.$moshaToast("Ievadi paroli!", this.$store.state.toast.moshaToastDanger)
          } else if (this.password !== this.password_repeat) {
            this.$moshaToast("Paroles nesakrīt!", this.$store.state.toast.moshaToastDanger)
          } else {
            this.axios.post(process.env.VUE_APP_API_LINK + '/users/create', {
              name: this.name,
              surname: this.surname,
              email: this.email,
              password: this.password
            }, {
              headers: {
                Authorization: 'Bearer ' + this.$store.state.token
              }
            })
                .then(response => {
                  if (response.data.status === true) {
                    this.$moshaToast(response.data.message, this.$store.state.toast.moshaToastSuccess)
                    this.$router.push('/users/list')
                  } else {
                    this.$moshaToast('Kaut kas nogāja greizi. Mēģini vēlreiz vai sazinies ar lapas administratoru!', this.$store.state.toast.moshaToastSuccess)
                  }
                })
          }
        }
      }
    }
  },
  mounted() {
    const route = useRoute()
    this.user_id = route.params.id
    if (this.user_id) {
      this.axios.post(process.env.VUE_APP_API_LINK + '/users/get', {
        user_id: this.user_id
      }, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      })
          .then(response => {
            if (response.data.status === true) {
              this.name = response.data.user.name
              this.surname = response.data.user.surname
              this.email = response.data.user.email
            }
          })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include block;

@include inputBlock;

@include buttonBlock(flex-end);
</style>
