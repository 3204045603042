import App from '@/App.vue'
import {createApp} from "vue"
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '@/router'
import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import store from '@/store'
import VueClickAway from "vue3-click-away"
import colorPicker from "vue3-colorpicker"
import "vue3-colorpicker/bundle.css"
import PageLoader from '@/components/page-loader/PageLoader'

const app = createApp(App)
app.use(router)
app.use(VueAxios, axios)
app.use(moshaToast)
app.use(store)
app.use(VueClickAway)
app.use(colorPicker)
app.component('PageLoader', PageLoader)
app.mount('#app')


