<template>
  <div v-html="question.content_html"></div>
  <div class="checkbox-answers">
    <div v-for="(answer, index) in question.answers" :key="answer" class="answer">
      <input v-if="!results.status" type="checkbox" :id="question.question_id + '' + answer.answer_id" :value="answer" v-model="checkedAnswers" v-on:change="checkboxChange()" class="checkbox-input">
      <label :for="question.question_id + '' + answer.answer_id" v-html="answer.content_html"></label>
      <div v-if="results.status" class="results" v-html="results.answers[index]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxButton',
  props: ['question', 'results'],
  emits: ['toggleSubmitButton'],
  data() {
    return {
      checkedAnswers: []
    }
  },
  mounted() {
    this.setCheckboxInputPosition()
    window.addEventListener('resize', this.setCheckboxInputPosition)

  },
  updated() {
    this.setCheckboxInputPosition()
    window.addEventListener('resize', this.setCheckboxInputPosition)
  },
  methods: {
    checkboxChange() {
      if (this.checkedAnswers.length > 0) {
        this.$emit('toggleSubmitButton', {
          status: true,
          answers: this.checkedAnswers
        })
      } else {
        this.$emit('toggleSubmitButton', false)
      }
    },
    setCheckboxInputPosition() {
      const all_answers = document.querySelectorAll('.answer')
      for (let i = 0; i < all_answers.length; i++) {
        const left_position = all_answers[i].querySelector('.u_body .u_row .container').getBoundingClientRect().left
        const input = all_answers[i].querySelector('.checkbox-input')
        if (input !== null) {
          input.style.left = left_position + 19 + 'px'
        }
        const results = all_answers[i].querySelector('.results')
        if (results !== null) {
          results.style.left = left_position + 5 + 'px'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include publicQuiz;

.results {
  width: 120px;
  padding: 10px;
  font-weight: bold;
}
</style>
