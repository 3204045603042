<template>
  <div class="content-wrapper">
    <div class="profile-wrapper">
      <div class="user-container">
        <div class="dropdown">
          <button class="dropbtn" @click="toggleSubmenu" v-click-away="onClickAway">
            {{ user.name }} {{ user.surname }}
          </button>
          <div class="dropdown-content" v-if="active">
            <a href="#" @click="goToProfile">Mans Profils</a>
            <a href="#" @click="logout">Iziet</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: 'Header',
  data() {
    return {
      active: false
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    toggleSubmenu() {
      this.active = !this.active;
    },
    logout() {
      this.axios.post(process.env.VUE_APP_API_LINK + '/profile/logout', {}, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      })
          .then(response => {
            if (response.data.status === true) {
              this.$store.commit('logoutUser')
              this.$router.push('/')
            }
          })
    },
    goToProfile() {
      this.active = false;
      this.$router.push('/profile')
    },
    onClickAway() {
      this.active = false;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

.content-wrapper {
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .profile-wrapper {
    background: #fff;
    display: flex;
    justify-content: flex-end;

    .user-container {
      .dropdown {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        .dropbtn {
          border: none;
          min-width: 200px;
          color: #fff;
          line-height: 40px;
          font-size: 16px;
          cursor: pointer;
          transition: 0.3s;
          height: 100%;
          background: $red-accent;
          font-weight: bold;

          &:hover {
            color: $red-accent;
            background-color: #faced9;
          }
        }

        .dropdown-content {
          position: absolute;
          background-color: $red-accent;
          z-index: 1;
          text-align: center;
          width: 100%;

          a {
            color: #fff;
            line-height: 36px;
            padding: 0 16px;
            text-decoration: none;
            display: block;

            &:hover {
              background: #faced9;
              color: $red-accent;
            }
          }
        }
      }

      @media only screen and (max-width: $large-breakpoint) {
        z-index: 999999;
      }
    }

    @media only screen and (max-width: $large-breakpoint) {
      padding: 0;
    }
  }
}
</style>
