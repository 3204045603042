<template>
  <div class="block">
    <div class="block-title">
      <h1>Aptaujas informācija</h1>
    </div>
    <div class="form-container">

      <div class="input-block">
        <label for="name">Nosaukums:</label>
        <input type="text" id="name" v-model="general_information.name" @change="$emit('updateGeneral', general_information)">
      </div>

      <div class="input-block">
        <label for="status">Statuss:</label>
        <select id="status" v-model="general_information.status" @change="$emit('updateGeneral', general_information)">
          <option value="0"></option>
          <option value="1">Aktīvs</option>
          <option value="2">Neaktīvs</option>
        </select>
      </div>

      <div class="input-block">
        <label>Aptaujas tips:</label>
        <div class="radio-buttons">
          <div class="radio-button">
            <input type="radio" name="quiz-type" v-model="general_information.type" value="1" id="first-quiz-type" @change="$emit('updateGeneral', general_information)">
            <label for="first-quiz-type">Aptauja pēc kuras aizpildīšanas tiek parādīta rezultātu lapa attiecīgi pēc sakrātajiem rezultātu lapu numuriem.</label>
          </div>
          <div class="radio-button">
            <input type="radio" name="quiz-type" v-model="general_information.type" value="2" id="second-quiz-type" @change="$emit('updateGeneral', general_information)">
            <label for="second-quiz-type">Aptauja ar vienu jautājumu pēc kuras aizpildīšanas tiek parādīts kopējais respondentu skaits katrai atbildei.</label>
          </div>
          <div class="radio-button">
            <input type="radio" name="quiz-type" v-model="general_information.type" value="3" id="third-quiz-type" @change="$emit('updateGeneral', general_information)">
            <label for="third-quiz-type">Aptauja ar pareizajām un nepareizajām atbildēm, kurā tiek parādīts vai jautājums tika atbildēts pareizi un beigās rezultātu lapa ar pareizo/nepareizo atbilžu attiecību.</label>
          </div>
          <div class="radio-button">
            <input type="radio" name="quiz-type" v-model="general_information.type" value="4" id="fourth-quiz-type" @change="$emit('updateGeneral', general_information)">
            <label for="fourth-quiz-type">Aptauja pēc kuras aizpildīšanas tiek parādīta rezultātu lapa attiecīgi pēc sakrātajiem rezultātu lapu punktiem.</label>
          </div>
        </div>
      </div>

      <div class="input-block">
        <label>Atļaut tikai vienu reizi pildīt:</label>
        <div class="radio-buttons">
          <div class="radio-button">
            <input type="radio" name="limited" v-model="general_information.limited" value="1" id="limited-yes" @change="$emit('updateGeneral', general_information)">
            <label for="limited-yes">Jā.</label>
          </div>
          <div class="radio-button">
            <input type="radio" name="limited" v-model="general_information.limited" value="0" id="limited-no" @change="$emit('updateGeneral', general_information)">
            <label for="limited-no">Nē.</label>
          </div>
        </div>
      </div>

      <div class="input-block">
        <label>Sākumlapas statuss:</label>
        <div class="radio-buttons">
          <div class="radio-button">
            <input type="radio" name="intro_status" v-model="general_information.intro_status" value="1" id="intro-yes" @change="$emit('updateGeneral', general_information)">
            <label for="intro-yes">Ieslēgta.</label>
          </div>
          <div class="radio-button">
            <input type="radio" name="intro_status" v-model="general_information.intro_status" value="0" id="intro-no" @change="$emit('updateGeneral', general_information)">
            <label for="intro-no">Izslēgta.</label>
          </div>
        </div>
      </div>

      <div class="input-block" v-if="parseInt(general_information.intro_status) === 1">
        <label>Sākumlapa:</label>
        <HTMLEditor ref="HTMLEditorContentForIntro" v-on:load="addHTMLEditorChangeListenerForIntro"/>
      </div>

      <div class="input-block">
        <label>"Turpināt" poga:</label>
        <HTMLEditor ref="HTMLEditorContent" v-on:load="addHTMLEditorChangeListener"/>
      </div>

    </div>
  </div>
</template>

<script>
import HTMLEditor from "../../html-editor/HTMLEditor";

export default {
  name: 'General',
  components: {HTMLEditor},
  emits: ['updateGeneral'],
  props: ['old_general_information'],
  data() {
    return {
      general_information: {
        name: null,
        type: null,
        status: null,
        limited: null,
        intro_status: null,
        intro_html: null,
        intro_json: null,
        next_button_html: null,
        next_button_json: null
      }
    }
  },
  watch: {
    old_general_information(new_value) {
      if (new_value) {
        this.general_information = new_value
        this.$refs.HTMLEditorContent.editor.loadDesign(JSON.parse(this.general_information.next_button_json))
      }
    }
  },
  methods: {
    addHTMLEditorChangeListener() {
      const self = this
      this.$refs.HTMLEditorContent.editor.addEventListener('design:updated', function () {
        self.$refs.HTMLEditorContent.editor.exportHtml((data) => {
              self.general_information.next_button_html = data.html
              self.general_information.next_button_json = data.design
              self.$emit('updateGeneral', self.general_information)
            }
        )
      })
    },
    addHTMLEditorChangeListenerForIntro() {
      const self = this
      this.$refs.HTMLEditorContentForIntro.editor.loadDesign(JSON.parse(this.general_information.intro_json))
      this.$refs.HTMLEditorContentForIntro.editor.addEventListener('design:updated', function () {
        self.$refs.HTMLEditorContentForIntro.editor.exportHtml((data) => {
              self.general_information.intro_html = data.html
              self.general_information.intro_json = data.design
              self.$emit('updateGeneral', self.general_information)
            }
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_includes.scss";

@include block;

@include inputBlock;
</style>
