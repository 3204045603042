<template>
  <div v-html="result_page" class="result-page"></div>
</template>

<script>
export default {
  name: 'ResultPage',
  props: ['content_html'],
  data() {
    return {
      result_page: null
    }
  },
  watch: {
    content_html: {
      immediate: true,
      handler: function (data) {
        this.result_page = data
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>